/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from 'react';
import { getLive, getTwitch } from '../tools/api';

export interface IAppProps {}

export interface IAppState {
    isLive: {
        broadcaster_login: string;
        display_name: string;
        game_name: string;
        thumbnail_url: string;
        title: string;
    }[];
}

export default class Streams extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);

        this.state = { isLive: [] };
    }
    protected async getStreams() {
        const isLive: {
            broadcaster_login: string;
            display_name: string;
            game_name: string;
            thumbnail_url: string;
            title: string;
        }[] = [];

        const getLoadedData = await getTwitch();
        const isLiveData = await getLive(getLoadedData);
        isLiveData.forEach((streamer) => {
            
            // streamer['is_live'] = true;
            if (streamer['is_live'] === true) {
                isLive.push({
                    broadcaster_login: streamer['broadcaster_login'],
                    display_name: streamer['display_name'],
                    game_name: streamer['game_name'],
                    thumbnail_url: streamer['thumbnail_url'],
                    title: streamer['title'],
                });
            }
        });
        console.log(isLive);
        
        this.setState({ isLive: isLive });
    }
    componentDidMount(): void {
        this.getStreams();
    }
    public render() {
        if (this.state.isLive.length === 0) {
            return (
                <div className="container" style={{ height: '1000px' }}>
                    <div className="row" >
                        <div className="col">
                            <h3>No one is currently live </h3>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container" style={{ height: '1000px' }}>
                    {this.state.isLive.map((v, ind) => {
                        const href = `https://www.twitch.tv/${v.broadcaster_login}`;
                        const imgHref = v.thumbnail_url;
                        return (
                            <div key={ind} className="row">
                                <div className="col">
                                    <h4>
                                        {v.display_name} is live now playing {v.game_name}
                                    </h4>
                                    <a href={href} target="_blank" rel="noreferrer">
                                        <img
                                            src={imgHref}
                                            style={{ width: '150px', height: '150px' }}
                                            alt="thumbnail"
                                        />
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }
    }
}
