/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from "react";
import team from "../tools/teamMembers.json";
export interface IAppProps {}

export interface IAppState {}

export default class Home extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <div className="home">
        <h1>Meet The Team</h1>
        <div className="teamMember container-fluid">
          <div className="row pictures">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className=" flip-box-front">
                    <img src="./icons/Kai.png" alt="Kai" />
                  </div>
                  <div className="flip-box-back">
                    <h3>KaiLunis</h3>
                    <span>
                      <a href="https://twitter.com/KaiLunis" title="Twitter">
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitter"
                        ></i>
                      </a>
                      <a href="https://www.twitch.tv/kailunis" title="Twitch">
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitch"
                        ></i>
                      </a>
                      <a href="https://discord.gg/ygxSHhqaEh" title="Discord">
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-discord"
                        ></i>
                      </a>
                    </span>
                    <p>{team.Bios.Kai}</p>
                    <p>{team["Additional-info"].KaiInfo1}</p>
                    <p>{team["Additional-info"].KaiInfo2}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className=" flip-box-front">
                    <img src="./icons/sasoraBae.png" alt="SasoraBae" />
                  </div>
                  <div className="flip-box-back">
                    <h3>SasoraBae</h3>
                    <span>
                      <a href="https://twitter.com/SasoraBae" title="Twitter">
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitter"
                        ></i>
                      </a>
                      <a href="https://www.twitch.tv/sasorabae" title="Twitch">
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitch"
                        ></i>
                      </a>
                      <a
                        href="https://www.youtube.com/@sasora-bae"
                        title="Youtube"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-youtube"
                        ></i>
                      </a>
                    </span>
                    <p>{team.Bios.Sasora}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className=" flip-box-front">
                    <img src="./icons/ArcticShy.png" alt="ArcticShy" />
                  </div>
                  <div className="flip-box-back">
                    <h3>ArcticShy</h3>
                    <span>
                      <a href="https://www.twitch.tv/ArcticShy " title="Twitch">
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitch"
                        ></i>
                      </a>
                      <a
                        href="https://x.com/arcticshy_?t=jB_P1KkOGaLvuEwwQmsebg&s=09
"
                        title="Twitter"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitter"
                        ></i>
                      </a>
                    </span>
                    <p>{team.Bios.ArcticShy}</p>
                    <p>{team["Additional-info"].ArcticShyInfo1}</p>
                    <p>{team["Additional-info"].ArcticShyInfo2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pictures">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className=" flip-box-front">
                    <img src="./icons/m00ns.gif" alt="m00ns_Draki " />
                  </div>
                  <div className="flip-box-back">
                    <h3>m00ns_Draki</h3>
                    <span>
                      <a
                        href="https://vstream.com/c/@m00ns_draki"
                        title="VStream"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-solid fa-video"
                        ></i>
                      </a>
                      <a
                        href="https://www.twitch.tv/m00ns_Draki"
                        title="Twitch"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitch"
                        ></i>
                      </a>
                      <a
                        href="https://youtube.com/@m00ns_Draki"
                        title="Youtube"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-youtube"
                        ></i>
                      </a>
                    </span>
                    <p>{team["Additional-info"].m00nsInfo1}</p>
                    <p>{team.Bios.m00ns}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className=" flip-box-front">
                    <img src="./icons/CheekyRed.gif" alt="Red " />
                  </div>
                  <div className="flip-box-back">
                    <h3>NevermoreRed</h3>
                    <span>
                      <a
                        href="https://www.twitch.tv/nevermorered"
                        title="Twitch"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitch"
                        ></i>
                      </a>
                      <a
                        href="https://youtube.com/@NevermoreRed?si=cok3RUmk9AyQJoSw"
                        title="Youtube"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-youtube"
                        ></i>
                      </a>
                    </span>
                    <p>{team.Bios.NevermoreRed}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="flip-box">
                <div className="flip-box-inner">
                  <div className=" flip-box-front">
                    <img src="./icons/arcus.png" alt="arcus" />
                  </div>
                  <div className="flip-box-back">
                    <h3>Arcus Rosica</h3>
                    <span>
                      <a
                        href="https://twitter.com/ArcusRosikaVT"
                        title="Twitter"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitter"
                        ></i>
                      </a>
                      <a
                        href="https://www.twitch.tv/arcusrosika"
                        title="Twitch"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitch"
                        ></i>
                      </a>
                    </span>
                    <p>{team.Bios.Arcus}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pictures">
            <div className="col-md-4 col-sm-6 col-xs-12">
              {/* <div className="flip-box">
                <div className="flip-box-inner">
                  <div className=" flip-box-front">
                    <img src="./icons/viibesie.png" alt="Viibesie" />
                  </div>
                  <div className="flip-box-back">
                    <h3>Viibesie</h3>
                    <span>
                      <a href="https://live.space/viibesie" title="Live Space">
                        <i
                          style={{ color: "white" }}
                          className="fa-solid fa-video"
                        ></i>
                      </a>
                      <a href="https://twitch.tv/viibesie" title="Twitch">
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitch"
                        ></i>
                      </a>
                      <a
                        href="https://www.youtube.com/@viibesie"
                        title="Youtube"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-youtube"
                        ></i>
                      </a>
                      <a href="https://twitter.com/viibesie" title="Twitter">
                        <i
                          style={{ color: "white" }}
                          className="fa-brands fa-twitter"
                        ></i>
                      </a>
                    </span>
                    <p>{team.Bios.viibesie}</p>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              {/* <div className="flip-box">
                                <div className="flip-box-inner">
                                    <div className=" flip-box-front">
                                        <img src="./icons/ArcticShy.png" alt="ArcticShy" />
                                    </div>
                                    <div className="flip-box-back">
                                        <h3>ArcticShy</h3>
                                        <span>
                                            <a href="https://www.twitch.tv/ArcticShy " title="Twitch">
                                                <i style={{ color: 'white' }} className="fa-brands fa-twitch"></i>
                                            </a>
                                            <a
                                                href="https://x.com/arcticshy_?t=jB_P1KkOGaLvuEwwQmsebg&s=09
"
                                                title="Twitter"
                                            >
                                                <i style={{ color: 'white' }} className="fa-brands fa-twitter"></i>
                                            </a>
                                        </span>
                                        <p>{team.Bios.ArcticShy}</p>
                                        <p>{team['Additional-info'].ArcticShyInfo1}</p>
                                        <p>{team['Additional-info'].ArcticShyInfo2}</p>
                                    </div>
                                </div>
                            </div> */}
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              {/* <div className="flip-box">
                                <div className="flip-box-inner">
                                    <div className=" flip-box-front">
                                        <img src="./icons/arcus.png" alt="arcus" />
                                    </div>
                                    <div className="flip-box-back">
                                        <h3>Arcus Rosica</h3>
                                        <span>
                                            <a href="https://twitter.com/ArcusRosikaVT" title="Twitter">
                                                <i style={{ color: 'white' }} className="fa-brands fa-twitter"></i>
                                            </a>
                                            <a href="https://www.twitch.tv/arcusrosika" title="Twitch">
                                                <i style={{ color: 'white' }} className="fa-brands fa-twitch"></i>
                                            </a>
                                        </span>
                                        <p>{team.Bios.Arcus}</p>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
