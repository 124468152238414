/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from 'react';
import { TwitchClip } from 'react-twitch-embed';
import Carousel from 'react-bootstrap/Carousel';

export interface IAppProps {}

export interface IAppState {}

export default class Clips extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);

        this.state = {};
    }

    public render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h3>Kai</h3>
                        <Carousel className="customCarousel" interval={null}>
                            <Carousel.Item>
                                <TwitchClip clip="DrabTallOysterRuleFive-VEypM6GyeOO5mRVb" autoplay={false} />
                            </Carousel.Item>
                            <Carousel.Item>
                                <TwitchClip clip="DelightfulLovelyCoffeeANELE-sdbv13vM07MAg13X" autoplay={false} />
                            </Carousel.Item>
                        </Carousel>
                    </div>

                    <div className="col">
                        <h3>ArcticShy</h3>
                        <Carousel className="customCarousel" interval={null}>
                            <Carousel.Item>
                                <TwitchClip clip="ImportantJollyLapwingBabyRage-8lzqDy_5Zwsex9Y_" autoplay={false} />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h3>SasoraBae</h3>
                        <Carousel className="customCarousel" interval={null}>
                            <Carousel.Item>
                                <TwitchClip clip="CulturedSplendidNewtPastaThat-rwi7iMuTuZ_Nvo9i" autoplay={false} />
                            </Carousel.Item>
                            <Carousel.Item>
                                <TwitchClip
                                    clip="TardyEphemeralElephantFreakinStinkin-O55KC1oZl2_TxxjE"
                                    autoplay={false}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <TwitchClip clip="StormyTastyPicklesWOOP-PLYHlh5HxCsJNrTb" autoplay={false} />
                            </Carousel.Item>
                            <Carousel.Item>
                                <TwitchClip clip="ClumsyDifferentChickenDxCat-jW23saTGxldbmhC6" autoplay={false} />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                   
                </div>
            </div>
        );
    }
}
