/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Clips from '../components/clipsComponent';
import Home from '../components/homeCompent';
import Streams from '../components/twitchStreams';
import { setTwitch } from './api';
import team from '../tools/teamMembers.json';

export interface IAppProps {}

export interface IAppState {
    lastLoadedTime: number;
    streams: {
        broadcaster_language: string;
        broadcaster_login: string;
        display_name: string;
        game_id: string;
        game_name: string;
        id: string;
        is_live: boolean;
        started_at: string;
        tag_ids: string[];
        tags: string[];
        thumbnail_url: string;
        title: string;
    }[];
}
export default class MainController extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);
        this.state = { lastLoadedTime: 0, streams: [] };
    }
    async componentDidMount(): Promise<void> {
        const teamMembers = team.streamers.map((v) => {
            return v.ID;
        });
        await setTwitch(teamMembers);        
    }
    render(): React.ReactNode {
        return (
            <div className="App">
                <header className="App-header">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h3>THE GILDED GROVE - FOREST & FAE</h3>
                            </div>
                            <div className="col"></div>
                            <div className="col">
                                {/* <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start search">
                                    <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder="Search..."
                                            aria-label="Search"
                                        />
                                    </form>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </header>
                <div>
                    <Tabs>
                        <TabList>
                            <Tab>Home</Tab>
                            <Tab>Livestreams</Tab>
                            <Tab>Clips</Tab>
                            {/* <Tab>Funny Stuff</Tab> */}
                        </TabList>

                        <TabPanel>
                            <Home />
                        </TabPanel>
                        <TabPanel>
                            <Streams />
                        </TabPanel>
                        <TabPanel>
                            <Clips />
                        </TabPanel>
                        {/* <TabPanel>
                            <h2>Coming Soon</h2>
                        </TabPanel> */}
                    </Tabs>
                </div>
            </div>
        );
    }
}
