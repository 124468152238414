import axios from 'axios';

interface TeamMembers {
    broadcaster_language: string;
    broadcaster_login: string;
    broadcaster_id?: string;
    display_name: string;
    game_id: string;
    game_name: string;
    id: string;
    is_live: boolean;
    started_at: string;
    tag_ids: string[];
    tags: string[];
    thumbnail_url: string;
    title: string;
}
const teamMembers: TeamMembers[] = [];
export const setTwitch = async (IDs: number[]) => {
    for (let index = 0; index < IDs.length; index++) {
        const ele = IDs[index];

        await axios
            .get(`https://api.twitch.tv/helix/channels?broadcaster_id=${ele}`, {
                headers: {
                    Authorization: `Bearer id5gbsffasa56jsnhxj1jkngrvhmcm`,
                    'Client-id': '0dyevgohhetnw65inf322mx313hb6k',
                },
            })
            .then((res) => {
                for (let index = 0; index < res.data.data.length; index++) {
                    const element = res.data.data[index];
                    if (element['broadcaster_id'] === ele.toString()) {
                        teamMembers.push(element);
                        return res.data.data[index];
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    return teamMembers;
};
export const getLive = async (teamMembers: TeamMembers[]) => {
    const currentlyLive: TeamMembers[] = [];
    for (let index = 0; index < teamMembers.length; index++) {
        const ele = teamMembers[index].broadcaster_login;
        const teamMemberID = teamMembers[index].broadcaster_id;
        //&live_only=true
        await axios
            .get(`https://api.twitch.tv/helix/search/channels?query=${ele}&live_only=true`, {
                headers: {
                    Authorization: `Bearer id5gbsffasa56jsnhxj1jkngrvhmcm`,
                    'Client-id': '0dyevgohhetnw65inf322mx313hb6k',
                },
            })
            .then((res) => {
                for (let index = 0; index < res.data.data.length; index++) {
                    const element = res.data.data[index];
                    if (element['id'] === teamMemberID) {
                        const alreadyIn = currentlyLive.find((tm) => tm.broadcaster_login === ele);
                        if (!alreadyIn) {
                            currentlyLive.push(element);
                        }
                        return res.data.data[index];
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    console.log(currentlyLive);

    return currentlyLive;
};
export const getTwitch = async () => {
    return teamMembers;
};
